:root {
  --swiper-theme-color: #fff !important;
}
section.slider {
  > .inside {
    img {
      width: 100%;
      display: block;
      height: calc(100vh - 6.55em);
      @include media("<=tablet") {
        height: 15em;
      }
      object-fit: cover;
      object-position: top center;
    }
    .release-details {
      position: absolute;
      padding: 20px 35px;
      background-color: rgba(0, 0, 0, 0.85);
      color: #ffffff;
      bottom: 9vh;
      right: 9vh;
      font-size: 18px;
      max-width: 480px;
      opacity: 0;
      @include media("<=tablet") {
        font-size: 0.6em;
        bottom: 5%;
        right: 5%;
      }
      h3 {
        margin: 0;
        color:$pink;
        // background: $gradient;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        display: inline-block;
        font-size: 2em;
      }
      h4 {
        margin: 0;
      }
      hr {
        margin: 0.8em 0;
        border: none;
        border-bottom: 1px solid $white;
      }
      .broadcast-times-description {
        margin-top: 0.5em;
        strong {
          font-weight: inherit;
        }
      }
    }
  }
  .react-slideshow-container {
    .arrow {
      width: 4em;
      box-sizing: border-box;
      padding: 0 1em;
      position: absolute;
      cursor: pointer;
      @include media("<=tablet") {
        font-size: 0.7em;
      }
      &.next-arrow {
        right:0;
      }
    }
  }
  [aria-roledescription="slide"] {
    &.active {
      .release-details {
        @keyframes up {
          0% {
            transform: translateY(100%);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
        animation: up ease-out 300ms 200ms forwards;
      }
    }
    .each-slide {
      direction: rtl;
      position: relative;
      img {
        user-select: none;
        pointer-events: none;
      }
    }
  }
  .default-nav {
    background: none;
    svg {
      font-size: 3em;
    }
  }
}

header.header {
  background: $black;
  display: flex;
  position: relative;
  color: $white;
  z-index: 1;
  &:after {
    position: absolute;
    content: "";
    top: 100%;
    width: 100%;
    height: 0.3em;
    left: 0;
    background: $gradient;
    z-index: 1;
  }
  @include media(">desktop") {
    align-items: stretch;
  }
  .logo-link {
    // margin-inline-end: auto;
  }
  section.logo {
    justify-content: space-between;
    padding: 0.6em 1.3em;
    // background: $white;
    height: 100%;
    display: inline-flex;
    align-items: center;
    @include media(">desktop") {
      padding: 0.6em 1em;
    }
    @include media(">hd") {
      padding: 0.6em 3em;
    }
    h1 {
      margin: 0;
      line-height: 1;
      font-size: 1em;
    }
    img {
      display: block;
      // width: 4.5em;
      width: 3.2em;
      min-width: 3.2em;
      // @include media(">desktop") {
      //   width: 4em;
      // }
    }
  }
  font-size: 0.95em;
  @include media(">hd") {
    font-size: 1em;
  }
  nav {
    @include media("<=desktop") {
      flex-grow: 1;
    }
    @include media(">tablet") {
      font-size: 1.3em;
      margin-inline-start: auto;
      margin-inline-end: auto;
      display: flex;
      align-items: center;
    }
    @include media(">realhd") {
      font-size: 1.4em;
    }
    ul {
      margin: 0;
      padding: 1em 1.2em;
      display: flex;
      list-style: none;
      flex-flow: column;
      gap: 0.6em;
      @include media(">desktop") {
        flex-flow: initial;
        gap: 0.8em;
      }
      @include media(">realhd") {
        gap: 1.3em;
        // width: 4em;
      }
      li {
        line-height: 1.1;
        @include media(">desktop") {
          &:not(:last-child):after {
            content: " : ";
            position: relative;
            left: -0.25em;
            opacity: 0.8;
            font-weight: 300;
          }
        }
        @include media(">realhd") {
          &:not(:last-child):after {
            left: -0.45em;
          }
        }
        white-space: nowrap;
        a {
          font-weight: 300;
          cursor: pointer;
          color: inherit;
          position: relative;
          opacity: 0.75;
          text-decoration: none;
          &:hover {
            opacity: 1;
          }
          &.is-active {
            opacity: 1;
            font-weight: 700;
            &:after {
              position: absolute;
              content: "";
              width: 100%;
              top: 100%;
              left: 0;
              background: $gradient;
              height: 3px;
            }
          }
        }
      }
    }
  }
  .search-button {
    margin: 1em;
    padding: 1em;
    // margin-inline-start: auto;
    width: 1.5em;
    height: 1.5em;
    box-sizing: content-box;

    background-size: 60% auto;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.83 31.71'%3E%3Cpath d='M147.86,70.51l-5.73-5.92a14.41,14.41,0,0,0,3.08-8.93,13.88,13.88,0,1,0-13.87,14.26A13.53,13.53,0,0,0,140,66.78l5.67,5.86a1.53,1.53,0,0,0,2.2-2.13ZM120.52,55.66a10.82,10.82,0,1,1,21.63,0A11.38,11.38,0,0,1,139,63.52l0,0-.07.07a10.57,10.57,0,0,1-7.58,3.24A11,11,0,0,1,120.52,55.66Z' transform='translate(-117.46 -41.4)'/%3E%3C/svg%3E");
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .left-side {
    // margin-inline-start: auto;
    // position: absolute;
    // left: 0;
    // top: 0;
    // height: 100%;
    @include media(">desktop") {
      font-size: 0.85em;
    }
    @include media(">realhd") {
      font-size: 1em;
    }
    display: inline-flex;
    .social-icons {
      height: 100%;
      display: flex;
      margin-inline-end: 0.5em;
      gap: 0.4em;
      @include media("<=tablet") {
        flex-direction: column-reverse;
        padding-block-end: 0.5em;
      }
      @include media(">tablet") {
        align-items: center;
        margin-inline-end: 1em;
        justify-content: center;
      }
      a {
        display: inline-block;
        padding: 0.15em;
        border: 2px solid;
        border-radius: 3em;
        line-height: 1.28;
        width: 1.7em;
        height: 1.7em;
        opacity: 0.6;
        text-align: center;
        color: inherit;
        @include media(">tablet") {
          font-size: 1.2em;
        }
        &:hover {
          color: $red;
          opacity: 1;
        }
      }
    }
    .name {
      // background: $gradient;
      // background-image: url("data:image/svg+xml,%3Csvg id='Isolation_Mode' data-name='Isolation Mode' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 146 128'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23New_Gradient_Swatch);%7D%3C/style%3E%3ClinearGradient id='New_Gradient_Swatch' y1='64' x2='146' y2='64' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23fff'/%3E%3Cstop offset='0' stop-color='%23fe0002'/%3E%3Cstop offset='1' stop-color='%23fe45c7'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpolygon class='cls-1' points='146 106 73 128 0 106 0 0 146 0 146 106'/%3E%3C/svg%3E");
      background-image: url("data:image/svg+xml,%3Csvg id='Isolation_Mode' data-name='Isolation Mode' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 146 128'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23New_Gradient_Swatch);%7D%3C/style%3E%3ClinearGradient id='New_Gradient_Swatch' y1='64' x2='146' y2='64' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23fff'/%3E%3Cstop offset='0' stop-color='%23fe0002'/%3E%3Cstop offset='1' stop-color='%23fe0002'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpolygon class='cls-1' points='146 106 73 128 0 106 0 0 146 0 146 106'/%3E%3C/svg%3E");
      background-size: auto 100%;
      background-position: center;
      color: $white;
      padding: 0.6em 0.7em;
      display: flex;
      position: relative;
      margin-inline-end: 1.1em;
      // margin-inline-end: 2vw;
      margin-inline-start: 0.2em;
      z-index: 9;
      line-height: 1.1;
      @include media("<=tablet") {
        height: 107%;
        // background: $gradient_vertical;
        > div {
          white-space: nowrap;
          width: 1em;
          padding-inline-start: 1em;
          transform: rotate(-90deg);
          transform-origin: left bottom;
          font-weight: bold;
          height: 0em;
        }
      }
      @include media(">tablet") {
        font-size: 1.5em;
        height: 140%;
        align-items: center;
        justify-content: center;
        text-align: center;
        .month {
          display: block;
          font-weight: 500;
          margin-top: -0.3em;
        }
        .year {
          display: block;
          font-weight: 500;
          font-size: 1.6em;
          letter-spacing: -0.02em;
        }
      }
    }
  }
}

.release-not-found {
  .header.header {
    .social-icons {
      flex-direction: row;
      align-items: center;
      padding-block-end: 0;
      margin-inline-end: 1em;
    }
  }
}

:root {
  --main-color-1: #fe45c5;
  --main-color-2: #fe0000;
  --ribbon-grad-color-1: #fe0000;
  --ribbon-grad-color-2: #fe0000;
}

$pink: var(--main-color-1);
$red: var(--main-color-2);
$light-gray: #e5e5e5;
$gray: #727272;
$dark-gray: #333333;
$black: #000;
$gradient: linear-gradient(0.75turn, $pink, $red);
$gradient_vertical: linear-gradient(0.5turn, $pink, $red);

// $border: #e8e8e8;
$border: #c2c2c2;
$white: #fff;
$text: $black;
$link: $black;

// $yellow: #F8FFB0;
// $dark-purple: #8A0088;
// $blue: #38c5c5;
// $dark-blue: #009091;

// .dark-purple-bgc {
//     background: $dark-purple;
// }
// .dark-blue-bgc {
//     background: $dark-blue;
// }
// .black-bgc {
//     background: $black;
// }

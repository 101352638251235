footer.footer {
  // background: $;
  text-align: center;
  padding: 1.5em 1.5em 1em;
  @include media("<desktop") {
    font-size: 0.85em;
  }
  a {
    display: inline-block;
    color: $link;
  }
  hr {
    border: 0px solid gray;
    background: white;
    opacity: 0.2;
    height: 1px;
    margin: 1em 0;
  }
  .extra {
    font-size: 0.85em;
  }
}

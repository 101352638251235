.is-loading {
  .not-loading-text {
    display: none;
  }
}
.is-not-loading {
  .loading-text {
    display: none;
  }
}

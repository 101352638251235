.summary-page {
  max-width: 930px;
  article.show {
    margin: 2em 0;
    &:nth-child(even) {
      .header-wrap {
        a {
          background-color: #191919;
        }
      }
    }
    .header-wrap {
      display: flex;
      flex-direction: column-reverse;
      @include media(">=tablet") {
        gap: 1.5em;
        flex-direction: row-reverse;
      }
      a {
        display: flex;
        flex-grow: 1;
        background-color: #eee;
        background-color: #252525;
        color: inherit;
        color: white;
        text-decoration: none;
        position: relative;
        transition: 150ms ease all;
        &:after {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          content: "";
          display: block;
          background: $gradient_vertical;
          width: 3px;
          height: 100%;
          transition: inherit;
          opacity: 0;
        }
        &:hover {
          background-color: #000;
          // filter: brightness(1.15);
          // &:after {
          //   opacity: 1;
          // }
        }
        header {
          width: 100%;
          > h2 {
            margin: 0;
            color: $red;
            background: $gradient;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            display: inline-block;
            font-weight: 500;
            font-size: 2.5em;
          }
          h3.subtitle {
            margin: 0;
            font-size: 1.3em;
          }
          .broadcast-times-description {
            font-weight: 400;
            color: $light-gray;
            opacity: 0.8;
            strong {
              font-weight: inherit;
            }
          }
          hr {
            margin: 1em 0;
            border: none;
            opacity: 0.6;
            border-bottom: 1px solid white;
          }
          padding: 1.5em;
        }
      }
      .video {
        @include media(">=tablet") {
          max-width: 35%;
          width: 14em;
        }
        @include media(">=desktop") {
          width: 20em;
        }
        @include media(">=hd") {
          width: 25em;
        }
        position: relative;
        .play-button {
          background: none;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          position: absolute;
          width: 100%;
          font-size: 3em;
          color: white;
          z-index: 1;
          text-shadow: 0 0.05em 0.12em rgba(0, 0, 0, 0.4);
          transition: transform 130ms cubic-bezier(0.165, 0.84, 0.44, 1);
          &:hover {
            transform: scale(1.05);
          }
        }
        &:after {
          content: "";
          display: block;
          padding-bottom: 75%;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
        }
      }
    }
    .content {
      margin: 1em 0 2em;
    }
  }
}

.serie {
  .broadcast-times-description {
    >*, * {
      color: white !important;
    }
  }
}
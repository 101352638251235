// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: $white;
  font-weight: 400;
  color: $black;
  font-family: $font, arial, sans-serif;
  line-height: 1.3;
}

// #container {
//   overflow-x: hidden;
// }

main {
  img {
    max-width: 100%;
    height: auto;
  }
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

pre {
  direction: ltr;
  overflow-x: auto;
  width: 100%;
  white-space: pre-wrap;
}

.preview-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  background: $gradient;
  color: $white;
  font-weight: 700;
  padding: 0.5em 0.9em;
  border-top-right-radius: 1em;
  font-size: 1.5em;
  opacity: 0.7;
  pointer-events: none;
}

.popup-content {
  @include media("<=tablet") {
    width: 90% !important;
  }
  .modal {
    a.close {
      cursor: pointer;
      text-decoration: none;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 2em;
      background: $gradient;
      font-weight: bold;
      color: white;
      border-radius: 2em;
      width: 1em;
      height: 1em;
      text-align: center;
      line-height: 0.96;
      margin: -0.5em;
      z-index: 1;
    }
  }
}

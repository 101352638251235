section.series {
  margin-top: 2em;
  margin-bottom: 2em;
  .section-title {
    background: $gradient;
    display: inline-block;
    padding: 0.3em 0.7em;
    padding-inline-start: 1.3em;
    color: $white;
    font-size: 1.7em;
    margin: 1em 0;
  }
  .filters {
    display: flex;
    align-items: center;
    @include media("<=tablet") {
      padding: 0 1.5em;
      flex-direction: column;
      gap: 0.5em;
    }
    @include media(">tablet") {
    }
    margin: 1em 0;
    .start {
      select {
        padding: 0.3em 0.5em;
        min-width: 8em;
        font-family: inherit;
      }
    }
    .end {
      display: inline-flex;
      align-items: center;
      @include media(">tablet") {
        margin-inline-start: auto;
      }
      @include media("<=tablet") {
        flex-direction: column;
        gap: 0.5em;
      }
      .search {
        position: relative;
        input {
          border-radius: 0;
          padding: 0.3em;
          border-top: none;
          border-left: none;
          border-right: none;
        }
        i {
          position: absolute;
          left: 0;
          bottom: 0.4em;
          opacity: 0.4;
        }
      }
      .orderby {
        @include media(">tablet") {
          margin-inline-start: 1.5em;
        }
        i {
          opacity: 0.4;
          padding-inline-end: 0.3em;
        }
        button {
          opacity: 0.6;
          background: none;
          color: inherit;
          font-weight: 400;
          padding: 0.3em 0.2em;
          font-family: inherit;
          min-width: 3.2em;
          text-align: center;
          &.active {
            font-weight: 700;
            opacity: 1;
          }
        }
      }
    }
  }
  & > .inside {
    display: grid;
    grid-gap: 1.3em;
    grid-template-columns: repeat(2, 1fr);
    @include media(">30em") {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media(">50em") {
      grid-template-columns: repeat(4, 1fr);
    }
    // @include media(">70em") {
    //   grid-template-columns: repeat(5, 1fr);
    // }
    // @include media(">90em") {
    //   grid-template-columns: repeat(6, 1fr);
    // }
    .serie {
      a {
        cursor: pointer;
        transition: 150ms ease all;
        text-decoration: none;
        // background-color: #eee;
        display: block;
        color: inherit;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        &:after {
          position: absolute;
          top: 0;
          width: 100%;
          content: "";
          display: block;
          background: $gradient;
          height: 2px;
          transition: inherit;
          opacity: 0;
        }
        &:hover {
          // opacity: 0.95;
          filter: brightness(1.15);
          &:after {
            opacity: 1;
          }
        }
        .img-wrap {
          position: relative;
          padding-bottom: 66.66%;
          img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top center;
          }
        }
        display: flex;
        flex-direction: column;
        .meta {
          margin-top: auto;
          // position: absolute;
          bottom: 0;
          width: 100%;
          // background-color: rgba(255, 255, 255, 0.95);
          // background: white;
          opacity: 0.8;
          font-weight: 500;
          // color: #555;
          padding: 0.4em 0.5em;
          // margin-top: 1em;
          display: flex;
          padding: 0 1em;
          .right {
            margin-inline-end: auto;
          }
          .left {
            font-size: 0.85em;
            text-align: end;
          }
        }
        .data {
          padding: 0.7em 0em 0.8em;
          background-color: #222;
          color: $white;
          .inside {
            padding: 0 1em;
          }
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          h3 {
            font-size: 1.3em;
            margin: 0 0 0.35em;
            font-weight: 500;
          }
          h4 {
            font-size: 1em;
            margin: 0;
            font-weight: 400;
          }
          .show-type {
            text-align: end;
            font-weight: 700;
            margin: auto 0 0;
            padding-top: 0.5em;
          }
          .broadcast-times-description {
            opacity: 0.7;
            margin-top: 0.2em;
            font-size: 0.9em;
            margin-bottom: 0.9em;
            strong {
              font-weight: inherit;
            }
            p {
              margin-bottom: 0.5em;
              &:last-child {
                // margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.side-label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  height: 100%;
  > .inside {
    // font-size: 1.3em;
    padding: 1.45em 0.5em 0.4em;
    min-width: 6.6em;
    line-height: 1;
    background: $gradient;
    color: white;
    font-weight: 500;
    display: block;
    transform: rotate(-45deg) translateX(-50%);
    transform-origin: top left;
    text-align: center;
  }
  &.gradient {
    > .inside {
      background: $gradient;
    }
  }
  &.pink {
    > .inside {
      background: $pink;
    }
  }
  &.red {
    > .inside {
      background: $red;
    }
  }
  &.black {
    > .inside {
      background: $black;
    }
  }
  &.hbo-label {
    > .inside {
      background: $black;
      color: white;
      text-transform: uppercase;
      font-weight: bold !important;
    }
  }
}

.airing-page {
  overflow-x: auto;
  .airing-table {
    display: grid;
    margin: 2em 0;
    // .row.header {
    //   div {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     text-align: center;
    //   }
    // }
    .row {
      //   grid-template-columns: repeat(6, 1fr);
      // grid-template-columns:
      //   minmax(100px, 4.33fr) minmax(100px, 2.33fr) minmax(100px, 2.33fr)
      //   minmax(100px, 2.33fr) minmax(100px, 2.33fr) minmax(100px, 2.33fr);

      grid-template-columns:
        minmax(100px, 4.33fr) minmax(100px, 2fr) minmax(100px, 2fr)
        minmax(100px, 2fr) minmax(100px, 2.93fr) minmax(100px, 2fr);

      .showText,
      .dayText,
      .dateText,
      .hourText {
        display: flex;
        align-items: center;
        // justify-content: center;
        // text-align: center;
        > div {
          width: 100%;
        }
      }

      &:hover {
        background-color: #f9f9f9;
      }
      display: grid;
      > * {
        color: inherit;
        hr {
          border: none;
          border-bottom: 1px solid $border;
        }
        padding: 0.4em 0.6em;
        &:first-child {
          border-right: 1px solid $border;
        }
        border-bottom: 1px solid $border;
        border-left: 1px solid $border;
        // text-align: center;
        img {
          object-fit: cover;
          object-position: top center;
          width: 100%;
          height: 4em;
          @include media(">tablet") {
            height: 5em;
          }
          @include media(">realhd") {
            height: 7em;
          }
        }
        .play {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          button.play-button {
            background: none;
            color: inherit;
            font-size: 2.5em;
            transition: transform 130ms cubic-bezier(0.165, 0.84, 0.44, 1);
            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
      &.header {
        font-weight: 700;
        background: $gradient;
        color: $white;
        > div {
          border-color: white;
        }
      }
    }
  }
}

:root .theme-hot8 {
  --main-color-1: #ff9207;
  --main-color-2: #f75402;
}

.theme-hot8 {
  @import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
  font-family: "Heebo", sans-serif;
  main {
    @include media(">=tablet") {
      margin-top: 4em;
    }
  }
  header.header {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: black;
      z-index: -1;
    }
    &:before,
    &:after {
      transform: skewY(1.3deg);
      transform-origin: top left;
    }
    nav ul li a {
      opacity: 1;
    }
    .logo {
      position: relative;
      top: 1em;
      padding-inline-start: 1.4em;
      padding-top: 0;
      padding-bottom: 0;
      img {
        width: 4em;
        max-width: 4em;
        @include media(">=realhd") {
          width: 5em;
          max-width: 5em;
        }
      }
    }
    .name {
      // background-image: url("data:image/svg+xml,%3Csvg id='Isolation_Mode' data-name='Isolation Mode' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 146 128'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23New_Gradient_Swatch);%7D%3C/style%3E%3ClinearGradient id='New_Gradient_Swatch' y1='64' x2='146' y2='64' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23fff'/%3E%3Cstop offset='0' stop-color='%23ff9207'/%3E%3Cstop offset='1' stop-color='%23f75402'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpolygon class='cls-1' points='146 106 73 128 0 106 0 0 146 0 146 106'/%3E%3C/svg%3E");
      // color: black;
      background-image: url("data:image/svg+xml,%3Csvg id='Isolation_Mode' data-name='Isolation Mode' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 146 128'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23New_Gradient_Swatch);%7D%3C/style%3E%3ClinearGradient id='New_Gradient_Swatch' y1='64' x2='146' y2='64' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23fff'/%3E%3Cstop offset='0' stop-color='%23000000'/%3E%3Cstop offset='1' stop-color='%23000000'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpolygon class='cls-1' points='146 106 73 128 0 106 0 0 146 0 146 106'/%3E%3C/svg%3E");
      color: var(--main-color-1);
      filter: drop-shadow(0 0 0.10em rgb(179, 179, 179));
      .month {
        font-size: 1.2em;
      }
    }
  }

  .summary-page {
    article.show {
      .header-wrap {
        gap: 0;
      }
    }
  }
  section.series {
    > .inside {
      .serie {
        a {
          .data {
            font-size: 0.89em;
            h3 {
              color: #ffa323;
            }
          }
          &:hover {
            .img-wrap {
              img {
                transform: scale(1.15);
              }
            }
          }
          .img-wrap {
            overflow: hidden;
            img {
              transition: transform 400ms ease;
            }
          }
          .meta {
            font-weight: 400;
            .show-type {
              font-weight: inherit;
            }
          }
        }
      }
    }
  }

  .side-label {
    > .inside {
      font-weight: 400;
      font-size: 1.2em;
      padding: 1.15em 0.5em 0.23em;
    }
    &.gradient {
      > .inside {
        background: #e8410a;
      }
    }
    &.black {
      > .inside {
        background: #ffa323;
      }
    }
  }

  .contact-page {
    .contact-types {
      margin-top: 5em;
      margin-bottom: 2em;
    }
    .logos {
      a {
        img {
          height: 6em;
        }
      }
    }
  }

  .archive-page {
    padding: 1.5em 0;
    .year {
      text-align: center;
      .releases {
        display: block;
        // display: grid;
        // gap: 1em;
        // grid-template-columns: repeat(2, 1fr);
        // @include media(">30em") {
        //   grid-template-columns: repeat(4, 1fr);
        // }
        // @include media(">50em") {
        //   grid-template-columns: repeat(5, 1fr);
        // }

        .post {
          // background-color: $dark-gray;
          text-align: center;
          flex-grow: 1;
          transition: 200ms ease all;
          a {
            display: inline-block;
            color: var(--main-color-2);
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            // justify-content: center;
            // height: 100%;
            // min-height: 6.5em;
            height: auto;
            min-height: auto;
            .month {
              //   margin-top: auto;
              color: inherit;
              display: block;
              font-weight: 400;
              padding: 0.5em 1em;
            }
          }
          background: none;
          &:hover {
            background: none;
            // background-color: $black;
          }
          .img-wrap {
            position: relative;
            width: 100%;
            flex-grow: 1;
            padding-bottom: 48%;
            img {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: top center;
            }
          }
        }
      }
    }
  }
}

.contact-types {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  @include media(">50em") {
    margin: 4em auto;
  }
  flex-flow: wrap;
  gap: 4em;
  .contact-type {
    // text-align: center;
    h5 {
      font-size: 1.2em;
    }
  }
}
.contacts {
  display: table;
  // margin: 0 auto;
  .contact {
    // text-align: center;
    margin-bottom: 0.6em;

    display: table-row;
    margin-bottom: auto;
    strong.nameAndRole {
      display: table-cell;
      text-align: start;
      padding-left: 1.5em;
      font-weight: 400;
    }
    a.email {
      display: table-cell;
      text-align: end;
      color: $gray;
    }
  }
}

.contact-page {
  .logos-title {
    margin: 1.5em 0 1em;
    font-size: 1.5em;
    text-align: center;
  }
  .logos {
    margin-top: 2.5em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1em;
    a {
      position: relative;
      img {
        height: 3em;
      }
      &:hover {
        img {
        //   opacity: 0.7;
        }
        &:after {
          content: "להורדה";
          position: absolute;
          bottom: 0;
          right: 0;
        //   left: 0;
        transform: translateY(80%);
          min-width: 100%;
          text-align: center;
          background-color: white;
          font-weight: 400;
          font-size: 0.85em;
          padding: 0.1em 0.3em;
          border: 1px solid gray;
        }
      }
    }
  }
}

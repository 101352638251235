@charset 'UTF-8';

// @import 'https://use.fontawesome.com/releases/v5.0.13/css/all.css';
// @import 'https://unpkg.com/swiper/swiper-bundle.min.css';

@import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
@import
  'vendors/include-media',
  'vendors/normalize';

// 3. Base stuff
@import
  'base/grid',
  'base/wraps',
  'base/colors',
  'base/fonts',
  'base/typography',
  'base/base',
  'base/headings',
  'base/search-wrap',
  'base/forms',
  'base/video-wrapper',
  'base/pretty-table',
  'base/responsive',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/section-series',
  'layout/show',
  'layout/slider',
  'layout/footer';

// 5. Components
@import
'components/button',
'components/colorful-sections',
'components/contacts',
'components/side-label',
'components/videos',
'components/popup',
'components/preview-warning',
'components/loading-text';

// 6. Page-specific styles
@import
  'pages/airing',
  'pages/summary',
  'pages/contact',
  'pages/archive',
  'pages/home';

// 7. Themes
@import
  'themes/hot8',
  'themes/default';





  




.loader-container {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.loader-container .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}
.loader-container .blure {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.archive-page {
  padding: 1.5em 0;
  .year {
    .releases {
      display: grid;
      gap: 1em;
      grid-template-columns: repeat(2, 1fr);
      @include media(">30em") {
        grid-template-columns: repeat(4, 1fr);
      }
      // @include media(">50em") {
      //   grid-template-columns: repeat(5, 1fr);
      // }

      .post {
        background-color: $dark-gray;
        text-align: center;
        flex-grow: 1;
        transition: 200ms ease all;
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          min-height: 6.5em;
        }
        &:hover {
          background-color: $black;
        }
        .img-wrap {
          position: relative;
          width: 100%;
          flex-grow: 1;
          padding-bottom: 48%;
          img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top center;
          }
        }
        .month {
          //   margin-top: auto;
          color: white;
          display: block;
          font-weight: 400;
          padding: 0.7em 1em;
        }
      }
    }
  }
}

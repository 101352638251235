section.show {
  margin: 2em 0;
  > .inside {
    display: flex;
    flex-direction: column;
    @include media(">=desktop") {
      flex-direction: row;
    }
    nav {
      display: block;
      min-width: 10em;
      @include media(">=desktop") {
        text-align: end;
      }
      // font-size: 0.85em;
      ul {
        @include media(">=desktop") {
          position: sticky;
          top: 0.8em;
        }
        margin: 0 0 1em;
        padding: 0;
        list-style: none;
        li {
          padding: 0.2em 0;
          @include media(">=desktop") {
            padding: 0.3em 0;
          }
          a {
            color: inherit;
            position: relative;
            cursor: pointer;
            text-decoration: none;
            &:hover {
              font-weight: 700;
            }
          }
          &.watch {
            a {
              color: $red !important;
              // font-weight: 700;
            }
          }
          &.active {
            font-weight: 700;
            a:after {
              position: absolute;
              content: "";
              width: 100%;
              top: 100%;
              left: 0;
              background: $gradient;
              height: 2px;
            }
          }
        }
      }
    }
    hr {
      border: 1px solid $border;
      border-width: 0;
      border-left-width: 1px;
      margin: 0 1.6em;
      height: initial;
    }
    .credit {
      font-size: 0.85em;
      margin-top: 1em;
      font-weight: 400;
    }
    .content {
      flex-grow: 1;
      .summary {
        img {
          width: 100%;
          max-height: 60vh;
          object-fit: cover;
          object-position: top center;
        }
      }
      .subtitle {
        font-size: 1.3em;
      }
      .extra-data {
        display: flex;
        gap: 2em;
        .broadcast-times-description {
          flex-grow: 1;
          p:last-child {
            margin-bottom: 0;
          }
        }
        .logos {
          .logo {
            margin-bottom: 0.7em;
            img {
              max-width: 8em;
            }
          }
        }
      }
      hr {
        border: 1px solid $border;
        border-width: 0;
        border-bottom-width: 1px;
        margin: 1.6em 0;
      }
      footer {
        text-align: end;
      }
      .reviews {
        .review {
          font-size: 1.5em;
          margin-bottom: 0.5em;
        }
      }
      .pictures-part {
        .pictures {
          display: grid;
          gap: 2em;
          @include media(">=tablet") {
            grid-template-columns: repeat(2, 1fr);
          }
          @include media(">=desktop") {
            grid-template-columns: repeat(3, 1fr);
          }
          .picture {
            // display: flex;
            .credit {
              margin-top: 0.7em;
              margin-bottom: 0em;
            }
            .inside {
              display: flex;
              position: relative;
              width: 100%;
              &:after {
                content: "";
                display: block;
                padding-top: 66%;
              }
              img {
                position: absolute;
                top: 0;
                left: 0;
                background: #eee;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
              }
              .links {
                font-size: 0.65em;
                @include media("<tablet") {
                  font-size: 0.8em;
                }
                position: absolute;
                text-align: left;
                left: 0;
                bottom: 0;
                padding: 0.5em;
                display: flex;
                gap: 0.5em;
                a {
                  display: inline-block;
                  padding: 0.45em 0.8em;
                  color: $black;
                  background-color: $white;
                  text-decoration: none;
                  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                  font-weight: 700;
                  &:hover {
                    background: $gradient;
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
